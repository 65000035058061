import React from 'react'
import { Container } from 'react-bootstrap'
import appEng from '../../../Img/LandingPage/AppImg/AppEng.png'
import appAcc from '../../../Img/LandingPage/AppImg/AppAc.png'
import './AppPresentationStyle.css'
import googlePlay from '../../../Img/googlePlay.png'
import appStore from '../../../Img/appStore.png'

const AppPresentation = () => {

    return(
        <div className='AppPresentation'>
            
            <div className='half-screen-container__'>
                <div className='text-side'>
                    <h2>Aplicação para Engenheiros</h2>
                    <p>Tens a oportunidade de explorar e participar em projetos de engenharia na tua região. Com um foco especial
                    na tua área específica de engenharia, a nossa app destaca projetos que estão disponíveis nas proximidades.
                    Além disso, facilitamos o processo de voluntariado, permitindo que te candidates para contribuir com as tuas
                    habilidades em projetos que realmente importam.</p>
                    <div className="app-icons">
                        <a href='https://play.google.com/store/apps/details?id=com.socialite.lct.esf' target='_blank' rel='noreferrer'>
                            <img src={googlePlay} alt="Google Play" className="Gplay-icon" />
                        </a>
                        <a href='https://apps.apple.com/pt/app/esf/id6738280971' target='_blank' rel='noreferrer'>
                            <img src={appStore} alt="App Store" className="appStore-icon" />
                        </a>
                </div>
                </div>
                
                <div className='image-side'>
                    <img src={appEng} alt='Your Alt Text' className='responsive-image' />
                </div>
            </div>
            

            <div className='half-screen-container__'>
                <div className='image-side'>
                    <img src={appAcc} alt='Your Alt Text' className='responsive-image' />
                </div>
                <div className='text-side'>
                    <h2>Aplicação para Associações</h2>
                    <p>Uma plataforma dedicada para publicar e gerir projetos de engenharia que a tua associação gostaria de ver resolvidos por engenheiros voluntários. Através da app, tens a capacidade de explorar perfis de engenheiros interessados, rever as suas habilidades e experiências, e aprovar ou rejeitar as suas candidaturas. </p>
                    <div className="app-icons">
                        <a href='https://play.google.com/store/apps/details?id=com.socialite.lct.esfa' target='_blank' rel='noreferrer'>
                            <img src={googlePlay} alt="Google Play" className="Gplay-icon" />
                        </a>
                        <a href='https://apps.apple.com/pt/app/esf-associa%C3%A7%C3%B5es/id6504824800' target='_blank' rel='noreferrer'>
                            <img src={appStore} alt="App Store" className="appStore-icon" />
                        </a>
                </div>
                </div>
            </div>

        </div>

     
        );
};

export default AppPresentation;