import React, {useState} from 'react';
import {Button, Container, Nav, Navbar, NavDropdown, Modal} from "react-bootstrap";
import logo from "../../Img/ESF_LOGO.png"
import logonew from "../../Img/ESF_LOGO_New.png"
import {useNavigate} from "react-router-dom";
import CartaDePrincipios from './Legal/principios';
import Regulamento from './Legal/regulamento';
import PoliticaDeDados from './Legal/politicadedados';

const Header = () => {

    const navigate = useNavigate();
    const [error,setError] = useState("");
    const signIn = async (e) => {
        e.preventDefault();
        setError("");
        try {
            navigate("/login")
        }catch (err){
            setError(err.message);
        }

    }

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);


    const [showModalRegulamento, setShowModalRegulamento] = useState(false);
    const handleShowModalRegulamento = () => setShowModalRegulamento(true);
    const handleCloseModalRegulamento = () => setShowModalRegulamento(false);

    const [showModalPoliticaDados, setShowModalPoliticaDados] = useState(false);
    const handleShowModalPoliticaDados = () => setShowModalPoliticaDados(true);
    const handleCloseModalPoliticaDados = () => setShowModalPoliticaDados(false);

    return (
        <Navbar bg="dark" variant="dark" fixed="top" expand="sm">
            <Container fluid>
                <Navbar.Brand href="javascript:void(0)">
                    <img src={logonew} width="130" alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-collapse" />
                <Navbar.Collapse id="navbar-collapse">
                    <div className="mr-auto d-flex justify-content-center">
                        <Nav>
                            <Nav.Link href="https://www.ordemdosengenheiros.pt/regiao-centro/pt/">Ordem dos Engenheiros Região Centro</Nav.Link>
                            
                        

                           
                            <NavDropdown title="Legal" id="legal-dropdown">
                              
                                <NavDropdown.Item href="javascript:void(0)" onClick={handleShowModalRegulamento}>Regulamento de Funcionamento</NavDropdown.Item>
                                <NavDropdown.Item href="javascript:void(0)" onClick={handleShowModalPoliticaDados}>Politica de Dados</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </div>

                    <div className={"ms-auto"}>
                        <form className="d-flex" onSubmit={signIn}>
                            <Button variant="outline-success" type={"submit"}>
                                Admin
                            </Button>
                        </form>
                    </div>

                </Navbar.Collapse>

                <Modal show={showModal} onHide={handleCloseModal} size="lg">
                    <Modal.Header closeButton>
                         <h3><strong>Carta de Princípios</strong></h3> 
                    </Modal.Header>
                    <Modal.Body>
                       <CartaDePrincipios/>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalRegulamento} onHide={handleCloseModalRegulamento} size="lg">
                    <Modal.Header closeButton>
                        <h3><strong>Regulamento de Funcionamento</strong></h3>
                    </Modal.Header>
                    <Modal.Body>
                        <Regulamento/>
                    </Modal.Body>
                </Modal>


                <Modal show={showModalPoliticaDados} onHide={handleCloseModalPoliticaDados} size="lg">
                    <Modal.Header closeButton>
                        <h3><strong>Politica de Dados</strong></h3>
                    </Modal.Header>
                    <Modal.Body>
                        <PoliticaDeDados/>
                    </Modal.Body>
                </Modal>
            </Container>
        </Navbar>
       
    );
};

export default Header;