import React from 'react'
import { Container } from 'react-bootstrap'
import '../../style/LandingPageStyle.css'


const About = () => {
  return (
    <Container className='about-container'>
        <p className="sobre-bottom-text">Bem-vindo ao Engenheiros Sem Fronteiras, uma plataforma que permite o encontro de engenheiros e estudantes de engenharia com vontade de resolver problemas com associações e entidades.
        </p>
    </Container>
  )
}

export default About