
import './App.css';

import "bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Dashboard from "./components/Dashboard/Dashboard";
import Projects from "./components/Dashboard/Projects"
import Users from "./components/Dashboard/Users";

import {SignIn} from "./components/SignIn";
import {UserAuthContextProvider} from "./context/UserAuthContext";
import React from "react";
import ProtectedRoute from "./context/ProtectedRoute";
import Home from "./components/Home";
import Associations from "./components/Dashboard/Associations";

import Privacy from './components/privacy';


function App() {

  return (



    <UserAuthContextProvider>
        <Routes>
            <Route path={"/"} element={<Home/>}/>
            <Route path={"/login"} element={<SignIn/>}/>

                <Route path="/privacy" Component={Privacy}/>

                <Route exact path={"/dashboard"} element={
                    <ProtectedRoute>
                        <Dashboard/>
                    </ProtectedRoute>
                }/>
                <Route exact path={"/dashboard/users"} element={
                    <ProtectedRoute>
                        <Users/>
                    </ProtectedRoute>
                }/>
                <Route exact path={"/dashboard/projects"} element={
                    <ProtectedRoute>
                        <Projects/>
                    </ProtectedRoute>
                }/>
            <Route exact path={"/dashboard/associations"} element={
                    <ProtectedRoute>
                        <Associations/>
                    </ProtectedRoute>
                }/>



        </Routes>
    </UserAuthContextProvider>



  );
}

export default App;
