// src/privacy/Privacy.jsx
import React from 'react';

const Privacy = () => {
    return (
        <div>
            <p>
                <strong>1)</strong> Os ESF respeitam as Diretivas e o Regulamento Geral de Proteção de Dados da União Europeia, de acordo com a Diretiva 95/46/CE.
            </p>
            <p>
                <strong>2)</strong> Os dados dos cidadãos e das associações são mantidos confidenciais e protegidos.
            </p>
            <p>
                <strong>3)</strong> Os ESF não utilizam os dados para efeitos de comercialização.
            </p>
        
            <p>
                <strong>4)</strong> Os cidadãos e as associações têm direito a:
                <p>
                    <strong>a.</strong> informação sobre o tratamento dos seus dados pessoais;
                </p>
                <p>
                    <strong>b.</strong> obter acesso aos dados pessoais conservados que lhes digam respeito;
                </p>
                <p>
                    <strong>c.</strong> solicitar a correção de dados pessoais incorretos, inexatos ou incompletos;
                </p>
                <p>
                    <strong>d.</strong> solicitar o apagamento de dados pessoais;
                </p>
                <p>
                    <strong>e.</strong> solicitar a limitação do tratamento dos seus dados pessoais em casos específicos;
                </p>
                <p>
                    <strong>f.</strong> solicitar que as decisões tomadas com base em tratamento automatizado que lhes digam respeito ou que os afetem significativamente e que se baseiem nos seus dados pessoais sejam tomadas por pessoas singulares e não apenas por computadores. Também têm o direito, neste caso, de manifestar o seu ponto de vista e de contestar a decisão.
                </p>
            </p>
        </div>
    );
};

export default Privacy;